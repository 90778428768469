import React from "react"
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Helmet bodyAttributes={{id: 'error'}}  />
    <div className="notFound">
      <h1>404</h1>
      <Link className="button secondary" to="/">Home</Link>
    </div>
  </>
)

export default NotFoundPage
